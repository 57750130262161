$( document ).ready( function()
{
    $( '.is-ident' ).keyup( function( evt ){
        if ( evt.which == 13 ) {
            evt.preventDefault();
        }

        updateIdent();
    }).change( function() {
        updateIdent();
    });

    window.updateIdent = function() {
        if ( !$( '.is-ident' ).length ) {
            return true;
        }

        var sTarget = $( '.is-ident' ).attr( 'data-ident-target' );
        var sIdent = $( '.is-ident' ).val().toLowerCase().trim()
            .replace( /[\s'`´\?\!\.,]/ig, "" )
            .replace( /[ö]/ig, "oe" )
            .replace( /[ä]/ig, "ae" )
            .replace( /[ü]/ig, "ue" )
            .replace( /[ß]/ig, "ss" );
        $( sTarget ).val( sIdent );
    }

    updateIdent();

    $('[data-toggle="confirmation"]').confirmation({
        placement: 'left',
        singleton: true,
        popout: true,
        onConfirm: function( event, element )
        {
            if ( element.is( 'button', 'input[type="submit"]' ) ) {
                element.parents( 'form' ).submit();
            }

            return false;
        }
    });

    $('.list-edit').each( function()
    {
        $(this).on('click', '.list-edit-remove', function()
        {
            if ( $('.list-edit-row').length == 1 ) return false;

            $(this).parents('.list-edit-row').remove();
        });

        $(this).find('.list-edit-add').click(function( event )
        {
            event.preventDefault();

            var group = $('.list-edit-row-template');
            var clone = group.clone();

            clone.removeClass('list-edit-row-template');
            clone.css('display', 'block');
            group.after( clone );

            // slim-select
            var select = clone.find('.slim-select-template');
            select.addClass('slim-select').removeClass('slim-select-template');
            initSlimSelect( select );
        });
    });

    $('.chosen-select').chosen({no_results_text: "Oops, nothing found!"});

    $('.messageReadToggle').click(function()
    {
        var id = $(this).data('id');
        $.post('/message/' + id + '/read', {checked: $(this).is(':checked')});
    });

    $('.comment').click(function(){
        let comment = $(this);
        let input = comment.next();
        comment.hide();
        input.show().focus();
    });

    function sendComment()
    {
        let input = $(this);
        let comment = input.prev();

        comment.show();
        input.hide();

        let id = input.data('id');
        let commentText = input.val();
        $.post('/orders/' + id + '/comment', {comment: commentText})
            .then(function (){
                comment.text( commentText );
            });
    }

    $('.commentEdit')
        .blur(function()
        {
            sendComment();
        })
        .keydown(function(event)
        {
            if (event.keyCode === 13)
            {
                sendComment();
            }
        });

});

$(function () {
    $.ajaxSetup({
        headers: {
            'X-XSRF-TOKEN': $('meta[name="_token"]').attr('content')
        }
    });
});
